 <template>
    <div>
        <Navbar :page="`Evento ${treinamento && treinamento.nome ? treinamento.nome : ''} `" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div v-if="treinamento" class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-4 2xl:col-span-3 bg-gray-100 py-3 px-4">
                    <h3 class="font-normal text-base py-1"> {{ treinamento.nome }}</h3>
                    <h3 v-if="treinamento.dataInicio" class="font-normal text-base py-2"> {{ treinamento.dataInicio | moment("DD/MM/YYYY HH:mm") }}</h3>
                </div>
                <div class="col-span-12 md:col-span-8 2xl:col-span-9 bg-gray-100 py-3 px-4">
                    <h3 class="font-normal text-base py-1">Canal: <b>{{ treinamento.transmissao.canalTransmissao }}</b></h3>
                    <iframe v-if="treinamento.transmissao.canalTransmissao === 'Online ao vivo'" class="mt-4 w-full" height="550" :src="treinamento.transmissao.linkEndereco" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div v-else-if="treinamento.transmissao.canalTransmissao === 'Online gravado'">
                        <iframe v-for="(link, idxLink) in treinamento.transmissao.links" :key="idxLink" class="mt-4 w-full" height="550" :src="link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <h3 v-else class="font-normal text-base py-1">Localização: <b>{{ treinamento.transmissao.linkEndereco }}</b></h3>
                    <label for="naoIncluiCertificado" class="block text-blue-700 text-base py-4 font-medium">
                        <input type="checkbox" v-model="assistiuConteudo" @change="changeConteudo()" class="rounded-sm" id="naoIncluiCertificado">
                        <span class="ml-2">
                            Confirmo que estive presente ao evento e me responsabilizo se acaso esta informação for constatada inverídica
                        </span>
                    </label>

                    <h3 class="font-semibold text-base py-1"> Material de apoio</h3>
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Nome
                                        </th>
                                        <th  scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                            Opção
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="item in treinamento.documentos" :key="item._id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-gray-500">
                                                    {{item.originalname ? item.originalname : ''}}
                                                </div>
                                            </div>
                                        </td>
                                        <td  class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="text-sm text-blue-500">
                                                    <a target="_blank" :href="`${url_api}/upload?mimetype=${item.mimetype}&filename=${item.filename}&folder=empresas`">Visualizar</a>
                                                </div>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            route: 'treinamentos',
            treinamento: null,
            assistiuConteudo: false,
            convidado: null
        }
    },
    methods: {
        async changeConteudo(){
            const id_convidao = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            if(id_convidao) {
                await this.$http.put(`/v1/${this.route}/convidados/${id_convidao}`,{ assistiuConteudo: this.assistiuConteudo, status: "Conteúdo assistido" });
            }
        },
        async start (){
            const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
            if(id) {
                const req = await this.$http.get(`/v1/${this.route}/convidados/${id}`);
                this.convidado = req.data;
                this.assistiuConteudo = req.data.assistiuConteudo;
                this.treinamento = req.data.treinamento;
            }
        },
        
    },
    async beforeMount() {
        this.start();
    },
}
</script>
